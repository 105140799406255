<template>
  <div class="newsite-wrap">
    <div class="img-wrap">
      <img :src="itemBg" class="itembg-img">
      <div>村情信息</div>
    </div>
    
    <div class="content">
      <van-tabs v-model="activeName" color="#3F7C53" title-active-color="#3F7C53" title-inactive-color="#999">
        <van-tab title="基本信息" name="a">
          <div class="title">基本信息</div>
           <van-field clickable
                      readonly
                      v-model="pageInfoShowVal.recommendSource"
                      label="站点推荐来源"
                      placeholder="请选择"
                      @click="siteSourceShow = true"
                      right-icon="arrow-down"></van-field>
            <van-field clickable
                      readonly
                      v-model="pageInfoShowVal.placeNature"
                      label="站点场所性质"
                      placeholder="请选择"
                      @click="sitePropShow = true"
                      right-icon="arrow-down"></van-field>
        </van-tab>
        <van-tab title="重点调查" name="b">
          <div class="title">人口信息</div>
          <van-field v-model="pageInfo.households"
                      label="户数"
                      placeholder="请输入本村户数"
                      type="digit"
                      maxlength="5"
                      :formatter="NumFormatter"></van-field>
          <van-field v-model="pageInfo.peopleNum"
                      label="人数"
                      placeholder="请输入本村人数"
                      type="digit"
                      maxlength="5"
                      :formatter="NumFormatter"></van-field>
          <van-field clickable
                      readonly
                      v-model="pageInfoShowVal.aboRatio"
                      label="原著居民占比"
                      placeholder="请选择"
                      @click="selectClick('aboRatio','原著居民占比')"
                      right-icon="arrow-down"></van-field>
          
          <div class="title">经济信息</div>
          <van-field v-model="pageInfo.perLand"
                      label="人均耕地(亩)"
                      placeholder="请输入人均耕地亩数"
                      maxlength="4"
                      type="number"
                      :formatter="LandFormatter"></van-field>
          <van-field clickable
                      readonly
                      label-width="4rem"
                      v-model="pageInfoShowVal.houseDisposableFound"
                      label="户均可支配资金"
                      placeholder="请选择"
                      @click="selectClick('houseDisposableFound','户均可支配资金')"
                      right-icon="arrow-down"></van-field>
          <van-field clickable
                      readonly
                      label-width="4rem"
                      v-model="pageInfoShowVal.carRatio"
                      label="私家车(占户数比例)"
                      placeholder="请选择"
                      @click="selectClick('carRatio','私家车(占户数比例)')"
                      right-icon="arrow-down"></van-field>
          
          <div class="title">居住信息</div>
          <van-field clickable
                      readonly
                      v-model="pageInfoShowVal.livingDensity"
                      label="居住密度"
                      placeholder="请选择"
                      @click="selectClick('livingDensity','居住密度')"
                      right-icon="arrow-down"></van-field>
          <div class="title">文化信息</div>
          <van-cell title="特色地方文化、历史名人等">
            <van-radio-group checked-color="#3F7C53"
                              class="radiogroup"
                              direction="horizontal"
                              v-model="pageInfo.distinctiveCulture">
              <van-radio name="1">有
                <template #icon="props">
                  <img class="img-icon" :src="props.checked ? checked : unchecked" />
                </template>
              </van-radio>
              <van-radio name="0">无 
                <template #icon="props">
                  <img class="img-icon" :src="props.checked ? checked : unchecked" />
                </template>
              </van-radio>
            </van-radio-group>
          </van-cell>
          <van-field v-if="pageInfo.distinctiveCulture == '1'"
                      clickable
                      readonly
                      v-model="pageInfoShowVal.regionalImpact"
                      label="地域影响"
                      placeholder="请选择"
                      @click="selectClick('regionalImpact','地域影响')"
                      right-icon="arrow-down"></van-field>

          <div class="mt2"></div>
          <van-cell title="美好示范乡村、明星村落等">
            <van-radio-group checked-color="#3F7C53"
                              class="radiogroup"
                              direction="horizontal"
                              v-model="pageInfo.demonstrationVillage">
              <van-radio name="1">有
                <template #icon="props">
                  <img class="img-icon" :src="props.checked ? checked : unchecked" />
                </template>
              </van-radio>
              <van-radio name="0">无 
                <template #icon="props">
                  <img class="img-icon" :src="props.checked ? checked : unchecked" />
                </template>
              </van-radio>
            </van-radio-group>
          </van-cell>
          <van-field v-if="pageInfo.demonstrationVillage == '1'"
                      clickable
                      readonly
                      v-model="pageInfoShowVal.demonstrationLevel"
                      label="示范等级"
                      placeholder="请选择"
                      @click="selectClick('demonstrationLevel', '示范等级')"
                      right-icon="arrow-down"></van-field>

          <div class="title">综合信息</div>
          <van-cell title="村集体经济">
            <van-radio-group checked-color="#3F7C53"
                              class="radiogroup"
                              direction="horizontal"
                              v-model="pageInfo.generalInfo.villageCollectiveEconomy">
              <van-radio name="1">有
                <template #icon="props">
                  <img class="img-icon" :src="props.checked ? checked : unchecked" />
                </template>
              </van-radio>
              <van-radio name="0">无 
                <template #icon="props">
                  <img class="img-icon" :src="props.checked ? checked : unchecked" />
                </template>
              </van-radio>
            </van-radio-group>
          </van-cell>
          <van-cell title="近年拆迁信息">
            <van-radio-group checked-color="#3F7C53"
                              class="radiogroup"
                              direction="horizontal"
                              v-model="pageInfo.generalInfo.recentDemolitionInfo">
              <van-radio name="1">有
                <template #icon="props">
                  <img class="img-icon" :src="props.checked ? checked : unchecked" />
                </template>
              </van-radio>
              <van-radio name="0">无 
                <template #icon="props">
                  <img class="img-icon" :src="props.checked ? checked : unchecked" />
                </template>
              </van-radio>
            </van-radio-group>
          </van-cell>
          <van-cell title="集贸市场">
            <van-radio-group checked-color="#3F7C53"
                              class="radiogroup"
                              direction="horizontal"
                              v-model="pageInfo.generalInfo.pedlarsMarket">
              <van-radio name="1">有
                <template #icon="props">
                  <img class="img-icon" :src="props.checked ? checked : unchecked" />
                </template>
              </van-radio>
              <van-radio name="0">无 
                <template #icon="props">
                  <img class="img-icon" :src="props.checked ? checked : unchecked" />
                </template>
              </van-radio>
            </van-radio-group>
          </van-cell>
          <van-cell title="快递收发点">
            <van-radio-group checked-color="#3F7C53"
                              class="radiogroup"
                              direction="horizontal"
                              v-model="pageInfo.generalInfo.expressPoint">
              <van-radio name="1">有
                <template #icon="props">
                  <img class="img-icon" :src="props.checked ? checked : unchecked" />
                </template>
              </van-radio>
              <van-radio name="0">无 
                <template #icon="props">
                  <img class="img-icon" :src="props.checked ? checked : unchecked" />
                </template>
              </van-radio>
            </van-radio-group>
          </van-cell>
          <van-cell title="水电气等代缴点">
            <van-radio-group checked-color="#3F7C53"
                              class="radiogroup"
                              direction="horizontal"
                              v-model="pageInfo.generalInfo.paymentPoint">
              <van-radio name="1">有
                <template #icon="props">
                  <img class="img-icon" :src="props.checked ? checked : unchecked" />
                </template>
              </van-radio>
              <van-radio name="0">无 
                <template #icon="props">
                  <img class="img-icon" :src="props.checked ? checked : unchecked" />
                </template>
              </van-radio>
            </van-radio-group>
          </van-cell>
          <van-cell title="同类站点">
            <van-radio-group checked-color="#3F7C53"
                              class="radiogroup"
                              direction="horizontal"
                              v-model="pageInfo.generalInfo.similarPoint">
              <van-radio name="1">有
                <template #icon="props">
                  <img class="img-icon" :src="props.checked ? checked : unchecked" />
                </template>
              </van-radio>
              <van-radio name="0">无 
                <template #icon="props">
                  <img class="img-icon" :src="props.checked ? checked : unchecked" />
                </template>
              </van-radio>
            </van-radio-group>
          </van-cell>

          <div class="title">周边金融网点</div>
          <van-checkbox-group v-model="pageInfo.nearFinancePoint">
            <van-checkbox :name="item.dictKey"
                          class="checkboxitem"
                          checked-color="#3F7C53"
                          v-for="(item, index) in selectList.nearFinancePoint"
                          :key="index">{{item.dictValue}}</van-checkbox>
          </van-checkbox-group>

          <div class="mt2"></div>
          <van-field clickable
                      readonly
                      v-model="pageInfoShowVal.nearFinancePointDistance"
                      label-width="4rem"
                      label="最近金融网点距离(KM)"
                      placeholder="请选择"
                      @click="selectClick('nearFinancePointDistance', '最近金融网点距离(KM)')"
                      right-icon="arrow-down" style="margin-bottom:1.5rem"></van-field>
        </van-tab>
        <van-tab title="补充调查" name="c">
          <div class="title">补充信息</div>
          <van-field clickable
                      readonly
                      v-model="pageInfoShowVal.mainIncomeSource"
                      label="主要经济来源"
                      placeholder="请选择"
                      @click="selectClick('mainIncomeSource', '主要经济来源')"
                      right-icon="arrow-down"></van-field>
          <van-field clickable
                      readonly
                      v-model="pageInfoShowVal.plantIndustry"
                      label="种植业类型"
                      placeholder="请选择"
                      @click="selectClick('plantIndustry', '种植业类型')"
                      right-icon="arrow-down"></van-field>

          <div class="mt2"></div>
          <van-cell title="是否有养殖业">
            <van-radio-group checked-color="#3F7C53"
                              class="radiogroup"
                              direction="horizontal"
                              v-model="pageInfo.hasBreedIndustry">
              <van-radio name="1">有
                <template #icon="props">
                  <img class="img-icon" :src="props.checked ? checked : unchecked" />
                </template>
              </van-radio>
              <van-radio name="0">无 
                <template #icon="props">
                  <img class="img-icon" :src="props.checked ? checked : unchecked" />
                </template>
              </van-radio>
            </van-radio-group>
          </van-cell>
          <van-field v-if="pageInfo.hasBreedIndustry == '1'"
                      clickable
                      readonly
                      v-model="pageInfoShowVal.breedIndustryType"
                      label="养殖类类型"
                      placeholder="请选择"
                      @click="selectClick('breedIndustryType', '养殖类类型')"
                      right-icon="arrow-down"></van-field>

          <div class="mt2"></div>
          <van-cell title="是否有制造业">
            <van-radio-group checked-color="#3F7C53"
                              class="radiogroup"
                              direction="horizontal"
                              v-model="pageInfo.hasManufacture">
              <van-radio name="1">有
                <template #icon="props">
                  <img class="img-icon" :src="props.checked ? checked : unchecked" />
                </template>
              </van-radio>
              <van-radio name="0">无 
                <template #icon="props">
                  <img class="img-icon" :src="props.checked ? checked : unchecked" />
                </template>
              </van-radio>
            </van-radio-group>
          </van-cell>
          <van-field v-if="pageInfo.hasManufacture == '1'"
                      clickable
                      readonly
                      v-model="pageInfoShowVal.manufactureType"
                      label="制造业类型"
                      placeholder="请选择"
                      @click="selectClick('manufactureType', '制造业类型')"
                      right-icon="arrow-down"></van-field>
          <van-field v-if="pageInfo.hasManufacture == '1'"
                      clickable
                      readonly
                      v-model="pageInfoShowVal.manufactureForm"
                      label="主要形式"
                      placeholder="请选择"
                      @click="selectClick('manufactureForm', '主要形式')"
                      right-icon="arrow-down"></van-field>
          <van-cell v-if="pageInfo.hasManufacture == '1'"
                    title="有无知名企业">
            <van-radio-group checked-color="#3F7C53"
                              class="radiogroup"
                              direction="horizontal"
                              v-model="pageInfo.hasFamousCompany">
              <van-radio name="1">有
                <template #icon="props">
                  <img class="img-icon" :src="props.checked ? checked : unchecked" />
                </template>
              </van-radio>
              <van-radio name="0">无 
                <template #icon="props">
                  <img class="img-icon" :src="props.checked ? checked : unchecked" />
                </template>
              </van-radio>
            </van-radio-group>
          </van-cell>

          <div class="mt2"></div>
          <van-cell title="是否有服务业">
            <van-radio-group checked-color="#3F7C53"
                              class="radiogroup"
                              direction="horizontal"
                              v-model="pageInfo.hasServiceIndustry">
              <van-radio name="1">有
                <template #icon="props">
                  <img class="img-icon" :src="props.checked ? checked : unchecked" />
                </template>
              </van-radio>
              <van-radio name="0">无 
                <template #icon="props">
                  <img class="img-icon" :src="props.checked ? checked : unchecked" />
                </template>
              </van-radio>
            </van-radio-group>
          </van-cell>
          <van-field v-if="pageInfo.hasServiceIndustry == '1'"
                      clickable
                      readonly
                      v-model="pageInfoShowVal.serviceIndustryType"
                      label="服务业类型"
                      placeholder="请选择"
                      @click="selectClick('serviceIndustryType', '服务业类型')"
                      right-icon="arrow-down"></van-field>
          <van-cell v-if="pageInfo.hasServiceIndustry == '1'"
                    title="周边旅游景点">
            <van-radio-group checked-color="#3F7C53"
                              class="radiogroup"
                              direction="horizontal"
                              v-model="pageInfo.hasNearScenicSpots">
              <van-radio name="1">有
                <template #icon="props">
                  <img class="img-icon" :src="props.checked ? checked : unchecked" />
                </template>
              </van-radio>
              <van-radio name="0">无 
                <template #icon="props">
                  <img class="img-icon" :src="props.checked ? checked : unchecked" />
                </template>
              </van-radio>
            </van-radio-group>
          </van-cell>

          <div class="title">参考项目</div>
          <van-cell title="网络信号">
            <van-radio-group checked-color="#3F7C53"
                              class="radiogroup"
                              direction="horizontal"
                              v-model="pageInfo.referItems.netSignal">
              <van-radio name="1">好
                <template #icon="props">
                  <img class="img-icon" :src="props.checked ? checked : unchecked" />
                </template>
              </van-radio>
              <van-radio name="0">差 
                <template #icon="props">
                  <img class="img-icon" :src="props.checked ? checked : unchecked" />
                </template>
              </van-radio>
            </van-radio-group>
          </van-cell>
          <van-cell title="是否为乡镇驻地村">
            <van-radio-group checked-color="#3F7C53"
                              class="radiogroup"
                              direction="horizontal"
                              v-model="pageInfo.referItems.townResident">
              <van-radio name="1">是
                <template #icon="props">
                  <img class="img-icon" :src="props.checked ? checked : unchecked" />
                </template>
              </van-radio>
              <van-radio name="0">否 
                <template #icon="props">
                  <img class="img-icon" :src="props.checked ? checked : unchecked" />
                </template>
              </van-radio>
            </van-radio-group>
          </van-cell>
          <van-field clickable
                      readonly
                      v-model="pageInfoShowVal.bankSuggest"
                      label="行方建议"
                      placeholder="请选择"
                      @click="selectClick('bankSuggest', '行方建议')"
                      right-icon="arrow-down" style="margin-bottom:1.5rem"></van-field>
        </van-tab>
      </van-tabs>
    </div>

    <div class="nextBtn flex" >
      <van-button class="publicBtnActive left-btn"
                  @click="holdClick" :disabled="hodeDisabled" :class="{'hodeDisabled': hodeDisabled}">暂存</van-button>
      <van-button class="publicBtnActive"
                  @click="nextBtn">下一步</van-button>
    </div>
    
    
    <van-popup v-model="sitePropShow"
               position="bottom">
      <van-picker show-toolbar
                  title="站点场所性质"
                  :columns="sitePropList"
                  @cancel="sitePropShow = false"
                  @confirm="sitePropComfirm" />
    </van-popup>

    <van-popup v-model="siteSourceShow"
               position="bottom">
      <van-picker show-toolbar
                  title="站点推荐来源"
                  :columns="siteSourceList"
                  @cancel="siteSourceShow = false"
                  @confirm="siteSourceComfirm" />
    </van-popup>

    <van-popup v-model="popupShow"
               v-if="popupShow"
               position="bottom">
      <van-picker show-toolbar
                  :title="popuptitle"
                  :columns="popupList"
                  @cancel="popupShow = false"
                  @confirm="popupConfirm" />
    </van-popup>

  </div>
</template>
<script>
import common from '@/utils/common'
import { saveVillageInfo, getDictByPage, tempVillageInfo, getVillageInfoByNo, getSurveyItems } from '@/utils/api'
import {appAction,uwStatisticAction} from "@/utils/util";
import {STATION_INFO_NEXT} from "@/utils/constantNum";
export default {
  name: 'NewSite',
  data () {
    let self = this
    return {
      itemBg:require('@/assets/img/site/itemBg.png'),
      activeName:'a',
      isFirstShowLong: false,
      isSecondShowLong: false,
      tabList: [
        { name: '基本信息' },
        { name: '重点调查' },
        { name: '补充调查' }
      ],
      selectedTab: 0,
      lineStyle: {},
      scrolled: false,
      // pageOne
      countryList: [],
      countryListAll: [],
      sitePropShow: false,
      sitePropList: [/* '自由','租赁','其它' */],
      sitePropApiList: [],
      isCanClick: false,
      local: null,
      areaCode: null,
      areaArr: { province_list: {}, city_list: {}, county_list: {} },

      siteSourceShow: false,
      siteSourceList: [],
      siteSourceApiList: [],
      pageOneInfo: {},
      // pageTwo
      popupVal: '',
      popupShow: false,
      popupList: [],
      popuptitle: '',
      pageInfo: {
        recommendSource: null,
        placeNature: null,
        households: null,
        peopleNum: null,
        aboRatio: null,
        perLand: null,
        houseDisposableFound: null,
        carRatio: null,
        livingDensity: null,
        distinctiveCulture: null,
        regionalImpact: null,
        demonstrationVillage: null,
        demonstrationLevel: null,
        generalInfo: {
          villageCollectiveEconomy: null,
          recentDemolitionInfo: null,
          pedlarsMarket: null,
          expressPoint: null,
          paymentPoint: null,
          similarPoint: null
        },
        nearFinancePoint: [],
        nearFinancePointDistance: null,
        mainIncomeSource: null,
        plantIndustry: null,
        hasBreedIndustry: null,
        breedIndustryType: null,
        hasManufacture: null,
        manufactureType: null,
        manufactureForm: null,
        hasFamousCompany: null,
        hasServiceIndustry: null,
        serviceIndustryType: null,
        hasNearScenicSpots: null,
        referItems: {
          netSignal: null,
          townResident: null
        },
        bankSuggest: null,
      },
      selectList: [],
      tempObj: null,
      pageInfoShowVal: {
        recommendSource: null,
        placeNature: null,
        aboRatio: null,
        houseDisposableFound: null,
        carRatio: null,
        livingDensity: null,
        regionalImpact: null,
        demonstrationLevel: null,
        nearFinancePointDistance: null,
        mainIncomeSource: null,
        plantIndustry: null,
        breedIndustryType: null,
        manufactureType: null,
        manufactureForm: null,
        serviceIndustryType: null,
        bankSuggest: null,
      },
      popupListArr: [],
      isSiteExist: false,
      nsCache:{},
      checked: require('@/assets/img/site/checked.png'),
      unchecked: require('@/assets/img/site/unchecked.png'),
      hodeDisabled: this.$route.query.status == 1 ? true : false,
      
    };
  },
  watch: {
    'pageInfo.distinctiveCulture' (newval) {
      if (newval == '0') {
        this.popupCancel('regionalImpact');
      }
    },
    'pageInfo.demonstrationVillage' (newval) {
      if (newval == '0') {
        this.popupCancel('demonstrationLevel');
      }
    },
    'pageInfo.hasBreedIndustry' (newval) {
      if (newval == '0') {
        this.popupCancel('breedIndustryType');
      }
    },
    'pageInfo.hasManufacture' (newval) {
      if (newval == '0') {
        this.popupCancel('manufactureType');
        this.popupCancel('manufactureForm');
        this.pageInfo.hasFamousCompany = null;
      }
    },
    'pageInfo.hasServiceIndustry' (newval) {
      if (newval == '0') {
        this.popupCancel('serviceIndustryType');
        this.pageInfo.hasNearScenicSpots = null;
      }
    },
    pageInfo:{
      handler(val){
        if(!common.getItem(common.getItem('stationNo'))) return
        let oldValue = common.getItem(common.getItem('stationNo')).sitecq ?common.getItem(common.getItem('stationNo')).sitecq:{}
        if(val){
          console.log("监听：",val)
          oldValue.recommendSource = val.recommendSource
          oldValue.placeNature = val.placeNature
          // 人口信息
          oldValue.households = val.households
          oldValue.peopleNum = val.peopleNum
          oldValue.aboRatio = val.aboRatio
          oldValue.perLand = val.perLand
          oldValue.houseDisposableFound = val.houseDisposableFound
          oldValue.carRatio = val.carRatio
          oldValue.livingDensity = val.livingDensity
          oldValue.distinctiveCulture = val.distinctiveCulture
          oldValue.regionalImpact = val.regionalImpact
          oldValue.demonstrationVillage = val.demonstrationVillage
          oldValue.demonstrationLevel = val.demonstrationLevel
          // 综合经济
          oldValue.generalInfo = {}
          oldValue.generalInfo.villageCollectiveEconomy = val.generalInfo.villageCollectiveEconomy
          oldValue.generalInfo.recentDemolitionInfo = val.generalInfo.recentDemolitionInfo
          oldValue.generalInfo.pedlarsMarket = val.generalInfo.pedlarsMarket
          oldValue.generalInfo.expressPoint = val.generalInfo.expressPoint
          oldValue.generalInfo.paymentPoint = val.generalInfo.paymentPoint
          oldValue.generalInfo.similarPoint = val.generalInfo.similarPoint
          //周边金融网点
          // oldValue.nearFinancePoint = JSON.stringify(val.nearFinancePoint)
          oldValue.nearFinancePoint = val.nearFinancePoint
          oldValue.nearFinancePointDistance = val.nearFinancePointDistance
          //补充证明
          oldValue.mainIncomeSource = val.mainIncomeSource
          oldValue.plantIndustry = val.plantIndustry

          oldValue.hasBreedIndustry = val.hasBreedIndustry
          oldValue.breedIndustryType = val.breedIndustryType
          oldValue.hasManufacture = val.hasManufacture
          oldValue.manufactureType = val.manufactureType
          oldValue.manufactureForm = val.manufactureForm
          oldValue.hasFamousCompany = val.hasFamousCompany
          oldValue.hasServiceIndustry = val.hasServiceIndustry
          oldValue.serviceIndustryType = val.serviceIndustryType
          oldValue.hasNearScenicSpots = val.hasNearScenicSpots
          // 参考项目
          oldValue.referItems = {}
          oldValue.referItems.netSignal = val.referItems.netSignal
          oldValue.referItems.townResident = val.referItems.townResident
          oldValue.bankSuggest = val.bankSuggest

          let cacheObj = common.getItem(common.getItem('stationNo'))
          cacheObj.sitecq = oldValue
          common.setItem(common.getItem('stationNo'), cacheObj)
        }
      },
      deep: true
    },
    pageInfoShowVal:{
      handler(val){
        if(!common.getItem(common.getItem('stationNo'))) return
        let oldValue = common.getItem(common.getItem('stationNo')).sitecqShow ?common.getItem(common.getItem('stationNo')).sitecqShow:{}
        if(val){
          oldValue.placeNature = val.placeNature
          oldValue.aboRatio = val.aboRatio
          oldValue.perLand = val.perLand
          oldValue.houseDisposableFound = val.houseDisposableFound
          oldValue.carRatio = val.carRatio
          oldValue.livingDensity = val.livingDensity
          oldValue.regionalImpact = val.regionalImpact
          oldValue.demonstrationLevel = val.demonstrationLevel
          oldValue.nearFinancePointDistance = val.nearFinancePointDistance
          oldValue.mainIncomeSource = val.mainIncomeSource
          oldValue.plantIndustry = val.plantIndustry
          oldValue.breedIndustryType = val.breedIndustryType
          oldValue.manufactureType = val.manufactureType
          oldValue.manufactureForm = val.manufactureForm
          oldValue.serviceIndustryType = val.serviceIndustryType
          oldValue.bankSuggest = val.bankSuggest

          let cacheObj = common.getItem(common.getItem('stationNo'))
          cacheObj.sitecqShow =  oldValue
          common.setItem(common.getItem('stationNo'), cacheObj)
        }
      },
      deep: true
    }
  },
  mounted () {
    this.$store.dispatch('keepAliveState/addKeepAliveAction', 'NewSite')
    this.getPageInfo() // 获取下拉框信息
    if(common.getItem(common.getItem('stationNo'))){
      this.getCache()
    }else{
      let key = common.getItem('stationNo')
      let value = {
        sitecq: {},
        sitecqShow: {},
        sitezz: {},
        sitezzShow: {}
      }
      common.setItem(key, value)
    }
    uwStatisticAction('/NewSite','新建站点')
  },
  methods: {
    togetVillageInfoByNo(){
      getVillageInfoByNo({stationNo: this.$route.query.stationNo}).then(res=>{
        if (res.code == 200) {
          if (res.data && res.data.businessCode == 200) {
            var siteInfo = res.data.data;

            // 站点推荐来源 站点场所性质回显
            this.sourceNatureKey = siteInfo.recommendSource ? siteInfo.recommendSource : ''
            for (let item of this.siteSourceApiList) {
              if (item.dictKey === this.sourceNatureKey) {
                this.pageInfoShowVal.recommendSource = item.dictValue
                break;
              }
            }
            this.placeNatureKey = siteInfo.placeNature ? siteInfo.placeNature : ''
            for (let item of this.sitePropApiList) {
              if (item.dictKey === this.placeNatureKey) {
                this.pageInfoShowVal.placeNature = item.dictValue
                break;
              }
            }

            this.pageInfo.recommendSource = this.sourceNatureKey
            this.pageInfo.placeNature = this.placeNatureKey 
          }
        }
      })
    },
    // 获取村情收集信息
    getItemsInfo(){
      getSurveyItems(this.$route.query.stationNo).then(res=>{
        if(res.code == 200){
          var itemsInfo = res.data;
          var inputInfoArray = ['households','peopleNum','perLand','distinctiveCulture','demonstrationVillage','hasBreedIndustry','hasManufacture','hasManufacture','hasFamousCompany','hasServiceIndustry','hasNearScenicSpots','peopleNum','peopleNum','peopleNum','peopleNum','peopleNum'];
          this.setInputInfo(inputInfoArray,itemsInfo);

          var inputgeneralInfoArray = ['villageCollectiveEconomy','recentDemolitionInfo','pedlarsMarket','expressPoint','paymentPoint','similarPoint'];
          this.setInputGeneralInfo(inputgeneralInfoArray,JSON.parse(itemsInfo.generalInfo));

          var selectedInfoArray = ['aboRatio','houseDisposableFound','carRatio','livingDensity','regionalImpact','demonstrationLevel','nearFinancePointDistance','mainIncomeSource','plantIndustry','breedIndustryType','manufactureType','manufactureForm','serviceIndustryType','bankSuggest'];
          this.setSeletedInfo(selectedInfoArray,itemsInfo);

          this.pageInfo.nearFinancePoint = itemsInfo.nearFinancePoint.split(',');
          this.pageInfo.referItems.netSignal = JSON.parse(itemsInfo.referItems).netSignal;
          this.pageInfo.referItems.townResident = JSON.parse(itemsInfo.referItems).townResident;
        }
      })
    },
    setInputInfo(infoArray,itemsDic){
      for (var obj of infoArray) {
        this.pageInfo[obj] = itemsDic[obj];
      }
    },
    setInputGeneralInfo(infoArray,itemsDic){
      for (var obj of infoArray) {
        this.pageInfo.generalInfo[obj] = itemsDic[obj];
      }
    },
    setSeletedInfo(infoArray,itemsDic){
      for (var obj of infoArray){
        this.pageInfoShowVal[obj] = this.getValue(this.selectList[obj],itemsDic[obj]);
        this.pageInfo[obj] = itemsDic[obj];
      }
      this.showManufactureType =  this.pageInfoShowVal['manufactureType'];
      if (this.showManufactureType.length > 15){
        this.showManufactureType =  this.showManufactureType.substring(0,15)+'...';
      }
    },
    // 获取配置文字
    getValue(array,keyString){
      for (let oneDict of array) {
        if (oneDict.dictKey == keyString){
          return oneDict.dictValue;
        }
      }
      return  '';
    },
    holdClick() {
      let params = {}
      let obj = this.pageInfo
      for(let key in obj){
        if (obj[key] != null && obj[key] != '' && obj[key] != undefined) {
          params[key] = obj[key]
        }
      }
      params.stationNo = this.$route.query.stationNo
      params.referItems = params.referItems ? JSON.stringify(params.referItems) : params.referItems
      params.nearFinancePoint = params.nearFinancePoint ? params.nearFinancePoint.toString() : params.nearFinancePoint
      params.generalInfo = params.generalInfo ? JSON.stringify(params.generalInfo) : params.generalInfo
      console.log('params: ', params)
      tempVillageInfo(params).then(res=>{
        if (res.code == 200) {
          if (res.data && res.data.businessCode == 200) {
            this.$toast('信息保存成功')
          }else{
            this.$toast(res.data.businessMessage)
          }
        }
      })
    },
    getCache() {
      let oldValue = common.getItem(common.getItem('stationNo')).sitecq ?common.getItem(common.getItem('stationNo')).sitecq:{}
      if(oldValue){
        this.pageInfo.recommendSource = oldValue.recommendSource
        this.pageInfo.placeNature = oldValue.placeNature
        // 人口信息
        this.pageInfo.households = oldValue.households
        this.pageInfo.peopleNum = oldValue.peopleNum
        this.pageInfo.aboRatio = oldValue.aboRatio
        this.pageInfo.perLand = oldValue.perLand
        this.pageInfo.houseDisposableFound = oldValue.houseDisposableFound
        this.pageInfo.carRatio = oldValue.carRatio
        this.pageInfo.livingDensity = oldValue.livingDensity
        this.pageInfo.distinctiveCulture = oldValue.distinctiveCulture
        this.pageInfo.regionalImpact = oldValue.regionalImpact
        this.pageInfo.demonstrationVillage = oldValue.demonstrationVillage
        this.pageInfo.demonstrationLevel = oldValue.demonstrationLevel

        this.pageInfo.generalInfo.villageCollectiveEconomy = oldValue.generalInfo.villageCollectiveEconomy
        this.pageInfo.generalInfo.recentDemolitionInfo = oldValue.generalInfo.recentDemolitionInfo
        this.pageInfo.generalInfo.pedlarsMarket = oldValue.generalInfo.pedlarsMarket
        this.pageInfo.generalInfo.expressPoint = oldValue.generalInfo.expressPoint
        this.pageInfo.generalInfo.paymentPoint = oldValue.generalInfo.paymentPoint
        this.pageInfo.generalInfo.similarPoint = oldValue.generalInfo.similarPoint
        this.pageInfo.nearFinancePoint = oldValue.nearFinancePoint
        this.pageInfo.nearFinancePointDistance = oldValue.nearFinancePointDistance

        this.pageInfo.mainIncomeSource = oldValue.mainIncomeSource
        this.pageInfo.plantIndustry = oldValue.plantIndustry
        this.pageInfo.hasBreedIndustry = oldValue.hasBreedIndustry
        this.pageInfo.breedIndustryType = oldValue.breedIndustryType
        this.pageInfo.hasManufacture = oldValue.hasManufacture
        this.pageInfo.manufactureType = oldValue.manufactureType
        this.pageInfo.manufactureForm = oldValue.manufactureForm
        this.pageInfo.hasFamousCompany = oldValue.hasFamousCompany
        this.pageInfo.hasServiceIndustry = oldValue.hasServiceIndustry
        this.pageInfo.serviceIndustryType = oldValue.serviceIndustryType
        this.pageInfo.hasNearScenicSpots = oldValue.hasNearScenicSpots
        this.pageInfo.referItems = oldValue.referItems
        this.pageInfo.referItems.netSignal = oldValue.referItems.netSignal
        this.pageInfo.referItems.townResident = oldValue.referItems.townResident
        this.pageInfo.bankSuggest = oldValue.bankSuggest

      }
      let oldValueShowVal = common.getItem(common.getItem('stationNo')).sitecqShow ?common.getItem(common.getItem('stationNo')).sitecqShow:{}
      if(oldValueShowVal){
        this.pageInfoShowVal.recommendSource = oldValueShowVal.recommendSource
        this.pageInfoShowVal.placeNature = oldValueShowVal.placeNature
        this.pageInfoShowVal.aboRatio = oldValueShowVal.aboRatio
        this.pageInfoShowVal.houseDisposableFound = oldValueShowVal.houseDisposableFound
        this.pageInfoShowVal.carRatio = oldValueShowVal.carRatio
        this.pageInfoShowVal.livingDensity = oldValueShowVal.livingDensity
        this.pageInfoShowVal.regionalImpact = oldValueShowVal.regionalImpact
        this.pageInfoShowVal.demonstrationLevel = oldValueShowVal.demonstrationLevel
        this.pageInfoShowVal.nearFinancePointDistance = oldValueShowVal.nearFinancePointDistance
        this.pageInfoShowVal.mainIncomeSource = oldValueShowVal.mainIncomeSource
        this.pageInfoShowVal.plantIndustry = oldValueShowVal.plantIndustry
        this.pageInfoShowVal.breedIndustryType = oldValueShowVal.breedIndustryType
        this.pageInfoShowVal.manufactureType = oldValueShowVal.manufactureType
        this.pageInfoShowVal.manufactureForm = oldValueShowVal.manufactureForm
        this.pageInfoShowVal.serviceIndustryType = oldValueShowVal.serviceIndustryType
        this.pageInfoShowVal.bankSuggest = oldValueShowVal.bankSuggest
      }
    },
    saveSiteInfo () {
      let params = {}
      let obj = this.pageInfo
      for(let key in obj){
        if (obj[key] != null && obj[key] != '' && obj[key] != undefined) {
          params[key] = obj[key]
        }
      }
      params.stationNo = this.$route.query.stationNo
      params.locateValue = JSON.stringify(params.locateValue)
      params.locateKey = JSON.stringify(params.locateKey)
      params.referItems = JSON.stringify(params.referItems)
      params.nearFinancePoint = params.nearFinancePoint.toString()
      params.generalInfo = JSON.stringify(params.generalInfo)
      console.log('params: ', params)
      saveVillageInfo(params).then(res => {
        if (res.code == 200) {
          if (res.data && res.data.businessCode == 200) {
            this.$toast(res.data.businessMessage)
            if (common.getItem('jumpFrom') != undefined ) {
              let p = {
                stationNo:this.$route.query.stationNo
              }
              appAction(STATION_INFO_NEXT,p)
            }else{
              this.$router.push({ name: 'villageScore', query: { score: JSON.stringify(res.data), stationNo: this.$route.query.stationNo } })
            }
            
          }else{
            this.$toast(res.data.businessMessage)
          }
        }
      })
    },
    nextBtn () {
      this.checkParams()
    },
    getPageInfo () {
      getDictByPage('stationPage').then(res => {
        if (res.code === 200) {
          let data
          if (res.encryptedStatus === '1') {
            data = JSON.parse(common.decrypt(res.data, res.timestamp));
          } else {
            data = res.data
          }
          if (data.businessCode === 200) {
            this.sitePropList = []
            this.siteSourceList = []
            this.sitePropApiList = JSON.parse(data.data).placeNature
            this.siteSourceApiList = JSON.parse(data.data).recommendSource
            for (let item of this.sitePropApiList) {
              this.sitePropList.push(item.dictValue)
            }
            for (let item of this.siteSourceApiList) {
              this.siteSourceList.push(item.dictValue)
            }
            this.selectList = JSON.parse(data.data)

            if(this.$route.query.hasSave == 1){ // 回显
              this.togetVillageInfoByNo()
              this.getItemsInfo()
            }

          }
        }
      })
    },
    NumFormatter (val) {
      if (val.length == 1) {
        return val.replace(/[^1-9]/g, "")
      } else {
        return val.replace(/\D/g, "")
      }
    },
    sitePropComfirm (val, i) {
      this.sitePropShow = false
      this.pageInfoShowVal.placeNature = val
      this.pageInfo.placeNature = this.sitePropApiList[i].dictKey
    },
    siteSourceComfirm (val, i) {
      this.siteSourceShow = false
      this.pageInfoShowVal.recommendSource = val
      this.pageInfo.recommendSource = this.siteSourceApiList[i].dictKey
    },
    checkParams () {
      if (!this.pageInfoShowVal.recommendSource) {
        this.$toast("请选择站点推荐来源")
        return
      }
      if (this.pageInfoShowVal.recommendSource=='存量B类') {
        this.$toast("推荐来源不能选择存量B类")
        return
      }
      if (!this.pageInfoShowVal.placeNature) {
        this.$toast("请选择站点场所性质")
        return
      }
      // pageTwo
      if (!this.pageInfo.households) {
        this.$toast('请输入村户数')
        return
      }
      if (this.pageInfo.households.length < 3) {
        this.$toast('村户数字段长度为3-5')
        return
      }
      if (!this.pageInfo.peopleNum) {
        this.$toast('请输入村人数')
        return
      }
      if (this.pageInfo.peopleNum.length < 3) {
        this.$toast('村人数字段长度为3-5')
        return
      }
      if (!this.pageInfo.aboRatio) {
        this.$toast('请选择原著居民占比')
        return
      }
      if (!this.pageInfo.perLand) {
        this.$toast('请输入人均耕地数')
        return
      }
      if (this.pageInfo.perLand > 99.9) {
        this.$toast('人均耕地数不能超过99.9亩')
        return
      }
      if (!this.pageInfo.houseDisposableFound) {
        this.$toast('请选择户均可支配资金')
        return
      }
      if (!this.pageInfo.carRatio) {
        this.$toast('请选择私家车比例')
        return
      }
      if (!this.pageInfo.livingDensity) {
        this.$toast('请选择居住密度')
        return
      }
      if (this.pageInfo.distinctiveCulture == '0') {
        this.pageInfo.regionalImpact = null
      }
      if (!this.pageInfo.distinctiveCulture) {
        this.$toast('请选择特色地方文化、历史名人等')
        return
      }
      if (!this.pageInfo.regionalImpact && this.pageInfo.distinctiveCulture == '1') {
        this.$toast('请选择地域影响')
        return
      }
      if (this.pageInfo.distinctiveCulture == '0') {
        this.pageInfo.regionalImpact = null
      }
      if (!this.pageInfo.demonstrationVillage) {
        this.$toast('请选择美好示范乡村、明星村落等')
        return
      }
      if (!this.pageInfo.demonstrationLevel && this.pageInfo.demonstrationVillage == '1') {
        this.$toast('请选择示范等级')
        return
      }
      if (this.pageInfo.demonstrationVillage == '0') {
        this.pageInfo.demonstrationLevel = null
      }

      if (!this.pageInfo.generalInfo.villageCollectiveEconomy) {
        this.$toast('请选择村集体经济')
        return
      }
      if (!this.pageInfo.generalInfo.recentDemolitionInfo) {
        this.$toast('请选择近年拆迁信息')
        return
      }
      if (!this.pageInfo.generalInfo.pedlarsMarket) {
        this.$toast('请选择集贸市场')
        return
      }
      if (!this.pageInfo.generalInfo.expressPoint) {
        this.$toast('请选择快递收发点')
        return
      }
      if (!this.pageInfo.generalInfo.paymentPoint) {
        this.$toast('请选择水电气等代缴点')
        return
      }
      if (!this.pageInfo.generalInfo.similarPoint) {
        this.$toast('请选择同类站点')
        return
      }

      if (this.pageInfo.nearFinancePoint.length == 0) {
        this.$toast('请选择周边金融网点')
        return
      }
      if (!this.pageInfo.nearFinancePointDistance) {
        this.$toast('请选择最近金融网点距离')
        return
      }

      // pageThree
      if (!this.pageInfo.mainIncomeSource) {
        this.$toast('请选择主要经济来源')
        return
      }
      if (!this.pageInfo.plantIndustry) {
        this.$toast('请选择种植业类型')
        return
      }
      if (!this.pageInfo.hasBreedIndustry) {
        this.$toast('请选择是否有养殖业')
        return
      }
      if (!this.pageInfo.breedIndustryType && this.pageInfo.hasBreedIndustry == '1') {
        this.$toast('选择养殖业类型')
        return
      }
      if (this.pageInfo.hasBreedIndustry == '0') {
        this.pageInfo.breedIndustryType = null
      }

      if (!this.pageInfo.hasManufacture) {
        this.$toast('请选择是否有制造业')
        return
      }
      if (!this.pageInfo.manufactureType && this.pageInfo.hasManufacture == '1') {
        this.$toast('请选择制造业类型')
        return
      }
      if (!this.pageInfo.manufactureForm && this.pageInfo.hasManufacture == '1') {
        this.$toast('请选择制造业主要形式')
        return
      }
      if (this.pageInfo.hasManufacture == '0') {
        this.pageInfo.manufactureType = null
        this.pageInfo.manufactureForm = null
        this.pageInfo.hasFamousCompany = null
      }
      if (!this.pageInfo.hasFamousCompany && this.pageInfo.hasManufacture == '1') {
        this.$toast('请选择有无知名企业')
        return
      }

      if (!this.pageInfo.hasServiceIndustry) {
        this.$toast('请选择是否有服务业')
        return
      }
      if (!this.pageInfo.serviceIndustryType && this.pageInfo.hasServiceIndustry == '1') {
        this.$toast('请选择服务业类型')
        return
      }
      if (!this.pageInfo.hasNearScenicSpots && this.pageInfo.hasServiceIndustry == '1') {
        this.$toast('请选择周边旅游景点')
        return
      }
      if (this.pageInfo.hasServiceIndustry == '0') {
        this.pageInfo.serviceIndustryType = null
        this.pageInfo.hasNearScenicSpots = null
      }
      if (!this.pageInfo.referItems.netSignal) {
        this.$toast('请选择网络信号')
        return
      }
      if (!this.pageInfo.referItems.townResident) {
        this.$toast('请选择是否为乡镇驻地村')
        return
      }
      if (!this.pageInfo.bankSuggest) {
        this.$toast('请选择行方建议')
        return
      }
      this.saveSiteInfo()
    },
    selectClick (obj, title) {
      this.popupShow = true
      this.popupList = []
      this.popuptitle = title
      for (let obj of this.selectList[obj]) {
        this.popupList.push(obj.dictValue)
      }
      this.popupListArr = this.selectList[obj]
      this.tempObj = obj
    },
    popupConfirm (value, index) {
      this.popupShow = false
      this.pageInfoShowVal[this.tempObj] = value
      this.pageInfo[this.tempObj] = this.popupListArr[index].dictKey

      this.pageInfo.manufactureType = this.pageInfoShowVal['manufactureType'];
      if (this.pageInfo.manufactureType && this.pageInfo.manufactureType.length > 15) {
        this.pageInfo.manufactureType = this.pageInfo.manufactureType.substring(0, 15) + '...';
      }
    },
    popupCancel (value) {
      this.pageInfoShowVal[value] = ''
      this.pageInfo[value] = ''
      if (value === 'manufactureType') {
        this.pageInfo.manufactureType = '';
      }
    },
    LandFormatter (val) {
      if (val > 99.9) {
        return 99.9
      } else {
        // 正则只能输入数字，一个小数点，第一位不能为小数点，保留1位小数，为正数
        return val.replace(/[^\d.]/g, '').replace(/\.{2,}/g, '.').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace(/^(\-)*(\d+)\.(\d).*$/, '$1$2.$3').replace(/^\./g, '')
      }
    },
  },
  beforeRouteLeave (to, from, next) {
    if (to.name == 'villageScore') {
    } else {
      this.$store.dispatch('keepAliveState/removeKeepAlive', 'NewSite')
    }
    next()
  },
}

</script>


<style lang="less" scoped>
.newsite-wrap{
  min-height: 100%;
  background: #F7F8F9;
  position: relative;
  .itembg-img{
    width: 100%;
  }
  .img-wrap{
    position: relative;
    div{
      color: #fff;
      font-size: 0.48rem;
      position: absolute;
      top: 1.5rem;
      left: 0.3rem;
    }
  }
  .nextBtn {
    width: 100%;
    padding: 12px;
    background: #fff;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 11;
    .left-btn{
      background: #fff;
      color:#333;
      border: 1px solid #E5E5E5;
      margin-right: 0.24rem;
    }
    .hodeDisabled{
      color: #E5E5E5;
      background: #fff;
    }
  }
  .content{
    background: #F7F8F9;
    width: 100%;
    border-top-left-radius: 0.4rem;
    border-top-right-radius: 0.4rem;
    margin-top: -1.3rem;
    position: relative;
    padding-bottom: 0.5rem;
    .title {
      text-align: left;
      background: #ffffff;
      height: 0.8rem;
      line-height: 0.8rem;
      padding: 0 0.3rem;
      color: #333333;
      font-weight: bold;
      font-size: 15px;
      margin-top: 0.2rem;
      &:nth-child(1){
        margin-top: 0;
      }
    }
    div.title:before {
      width: 8px;
      height: 5px;
      color: #3f7c53;
      content: "| ";
    }
  }
  .flex {
    display: flex;
  }
  .img-icon{
    width:0.4rem;
  }
  .newsite-flex {
    padding-bottom: 0.2rem;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    box-sizing: border-box;
    color: #3f7c53;
  }
  .mt2 {
    margin-top: 0.2rem;
  }
  .title {
    text-align: left;
    background: #ffffff;
    height: 0.8rem;
    line-height: 0.8rem;
    padding: 0 0.3rem;
    color: #333333;
    font-weight: bold;
    font-size: 15px;
    margin-top: 0.2rem;
  }
  div.title:before {
    width: 8px;
    height: 5px;
    color: #3f7c53;
    content: "| ";
  }
  .tab-content {
    padding-bottom: 1rem;
  }
  .radiogroup {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .checkboxitem {
    background: #fff;
    height: 0.8rem;
    line-height: 0.8rem;
    padding: 0.2rem 0.32rem;
  }
  
}
</style>

<style lang="less">
.newsite-wrap{
  .van-tabs__wrap{
    border-top-left-radius: 0.4rem !important;
    border-top-right-radius: 0.4rem;
  }
  .van-radio__icon{
    line-height: 0.5em;
  }
}
.newsite-wrap input {
  text-align: right;
}
.newsite-wrap .van-picker__confirm {
  color: #3f7c53;
}
.newsite-wrap .van-picker__cancel {
  color: #3f7c53;
}
.newsite-wrap .van-tab__text {
  font-size: 15px;
}
.newsite-wrap .van-cell {
  min-height: 48px;
}
.newsite-wrap .van-cell__title span {
  font-size: 15px;
  color: #333333;
  white-space: nowrap;
  display: inline-block;
}
.newsite-wrap .van-field__control {
  font-size: 15px;
}
.newsite-wrap .van-radio {
  font-size: 15px;
}
</style>